const script = document.createElement('script')
script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js'
script.onload = () => {
    window.pdfjsLib.GlobalWorkerOptions.workerSrc =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js'
}
document.head.appendChild(script)

const createPdfPreview = async pdfBytes => {
    // Load pdf doc
    const loadingTask = window.pdfjsLib.getDocument({ data: pdfBytes })
    const pdf = await loadingTask.promise

    // get first page
    const page = await pdf.getPage(1)

    // config canvas
    const viewport = page.getViewport({ scale: 1.5 })
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width

    // render page in canvas
    const renderContext = {
        canvasContext: context,
        viewport: viewport,
    }

    await page.render(renderContext).promise

    // canvas to base64
    return canvas.toDataURL('image/png')
}

export const loadPDFPreview = async url => {
    const pdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const previewUrl = await createPdfPreview(pdfBytes)
    return previewUrl
}
